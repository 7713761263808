<script>
import useVuelidate from '@vuelidate/core'
import {maxLength} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDropdown from "@components/input/AkDropdown";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkInputNumber from "@components/input/AkInputNumber";
import AkCheckbox from "@components/input/AkCheckbox";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanMaterielService from "@services/bilanMaterielService";
import rolePermissionService from "@services/rolePermissionService";
import circuitService from "@services/circuitService";

export default {
  components: {AkFormView, AkFormSubmitted, AkDropdown, AkInputText, AkInputTextArea, AkInputNumber, AkCheckbox},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan.update",
    }
  },
  data() {
    return {
      submitted: false,
      current: {},
      circuits: [],
      truckTypes: [
        {label: "16 T", value: "16 T"},
        {label: "19 T", value: "19 T"},
        {label: "26 T", value: "26 T"}
      ],
      volumeUnits: [
        {label: this.$t('bilan_materiel.v3m3'), value: "v3m3"},
        {label: this.$t('bilan_materiel.v4a45m3'), value: "v4a45m3"},
        {label: this.$t('bilan_materiel.v5m3'), value: "v5m3"},
        {label: this.$t('bilan_materiel.v6m3'), value: "v6m3"},
        {label: this.$t('bilan_materiel.v7a8m3'), value: "v7a8m3"}
      ],
    }
  },
  validations() {
    return {
      current: {
        brand: {maxLength: maxLength(100)},
        problemComment: {maxLength: maxLength(500)},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
    
    let p1 = bilanMaterielService.findById(this.$route.params.id);
    p1.then(data => this.current = data);
    
    let p2 = circuitService.findAll({});
    p2.then(data => this.circuits = data);
  
    let p3 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.equipmentReview.edit);
    
    Promise.all([p1, p2, p3]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {
    update() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.update"));
        return;
      }
      
      this.getRef().showLoader(this.$refs.form)
      bilanMaterielService.update(this.current).then(data => {
        let msg = this.$t("bilan.updated");
        this.$router.replace({ path: `/bilan/materiel/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
        }
      );
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.update')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkCheckbox :disabled=true
                      v-model="current.mule"
                      :label="$t('bilan_materiel.new_mule')"
                      class-name="col-md-3"/>
        </div>
        <div class="form-row">
          <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-3" :label="$t('ager_label')"/>
          <AkInputText v-if=!current.mule :disabled=true v-model=current.uerLabel class-name="col-md-3" :label="$t('uer_label')"/>
          <AkInputText v-if=!current.mule :disabled=true v-model=current.ceiLabel class-name="col-md-3" :label="$t('cei_label')"/>
          <AkDropdown 
              v-if=!current.mule 
              :label="$t('circuit_label')"
               v-model="current.circuitId"
               :options=this.circuits
               option-value="id"
               option-label="axis"
               class-name="col-md-3"/>
        </div>
        <h5>{{ $t('bilan_materiel.truck_info') }}</h5>
        <div class="form-row">
          <AkDropdown :label="$t('bilan_materiel.truck_type')"
                      v-model="current.truckType"
                      :submitted=this.submitted
                      :options=this.truckTypes
                      option-value="value"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkInputText :label="$t('bilan_materiel.brand')"
                       v-model="current.brand"
                       :validator="v$.current.brand"
                       :submitted=this.submitted
                       class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkCheckbox v-model="current.location"
                      :label="$t('bilan_materiel.rent')"
                      class-name="col-md-3"/>
        </div>
        <h5>{{ $t('bilan_materiel.salting') }}</h5>
        <div class="form-row">
          <AkDropdown :label="$t('bilan_materiel.salt_unit')"
                      v-model="current.volumeUnit"
                      :submitted=this.submitted
                      :options=this.volumeUnits
                      option-value="value"
                      option-label="label"
                      class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkCheckbox v-model="current.brine"
                      :label="$t('bilan_materiel.brine')"
                      class-name="col-md-4"/>
        </div>
        <h5>{{ $t('bilan_materiel.repairs_and_problems') }}</h5>
        <div class="form-row">
          <AkInputNumber :label="$t('bilan_materiel.nb_repair_materiel_vh')"
                         v-model="current.repairNumber"
                         class-name="col-md-4"/>
    
          <AkInputTextArea :label="$t('bilan_materiel.problem_met_and_suggestions')"
                           v-model="current.problemComment"
                           :validator="v$.current.problemComment"
                           :submitted=this.submitted
                           class-name="col-md-8"/>
        </div>
        <div class="float-right">
          <button v-if=this.canEditEquipment() @click=update() class="btn btn-primary" style="margin-left: 10px">{{ $t('update') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>